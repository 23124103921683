import { Link } from "gatsby";
import React from "react";
import { InfoEmail } from "./Emails";
import { siteLinks } from "./Header";
import { SocialIcons } from "./SocialIcons";

export function Footer() {
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__row">
          <img className="footer__logo" src={"/logo.svg"} />
          <div className="footer__row ">
            <div className="footer__links">
              {siteLinks.map((link) => (
                <Link key={link.to} to={link.to} activeClassName="active">
                  {link.children}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="footer__row">
          <div className="footer__info">
            <InfoEmail />
          </div>
          <div className="footer__socials">
            <SocialIcons />
          </div>
        </div>
      </div>
    </footer>
  );
}
