import React from "react";

import IconFacebook from "../svgs/IconFacebook.svg";
import IconInstagram from "../svgs/IconInstagram.svg";
import IconTwitter from "../svgs/IconTwitter.svg";
import IconTiktok from "../svgs/IconTiktok.svg";
import IconSnapchat from "../svgs/IconSnapchat.svg";

export function SocialIcons({}) {
  return (
    <div className={`social-icons`}>
      <a
        href="https://www.facebook.com/groups/ManchesterStudentsGroup"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconFacebook />
      </a>
      <a
        href="https://www.instagram.com/manchesterstudentsgroup/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconInstagram />
      </a>
      {/* <a
        href=""
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconTwitter />
      </a> */}
      <a
        href="https://www.tiktok.com/@manchesterstudentsgroup?_t=8VIVAlBtzjK"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconTiktok />
      </a>
      {/* <a
        href=""
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconSnapchat />
      </a> */}
    </div>
  );
}
