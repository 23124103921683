// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-18-things-i-wish-i-knew-in-first-year-js": () => import("./../../../src/pages/articles/18-things-i-wish-i-knew-in-first-year.js" /* webpackChunkName: "component---src-pages-articles-18-things-i-wish-i-knew-in-first-year-js" */),
  "component---src-pages-articles-advice-from-a-third-year-to-a-fresher-js": () => import("./../../../src/pages/articles/advice-from-a-third-year-to-a-fresher.js" /* webpackChunkName: "component---src-pages-articles-advice-from-a-third-year-to-a-fresher-js" */),
  "component---src-pages-articles-fallowfields-most-iconic-streets-js": () => import("./../../../src/pages/articles/fallowfields-most-iconic-streets.js" /* webpackChunkName: "component---src-pages-articles-fallowfields-most-iconic-streets-js" */),
  "component---src-pages-articles-freshers-week-dos-and-donts-js": () => import("./../../../src/pages/articles/freshers-week-dos-and-donts.js" /* webpackChunkName: "component---src-pages-articles-freshers-week-dos-and-donts-js" */),
  "component---src-pages-articles-index-js": () => import("./../../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-articles-new-zealand-wines-wins-independent-retailer-of-the-year-js": () => import("./../../../src/pages/articles/new-zealand-wines-wins-independent-retailer-of-the-year.js" /* webpackChunkName: "component---src-pages-articles-new-zealand-wines-wins-independent-retailer-of-the-year-js" */),
  "component---src-pages-events-featured-js": () => import("./../../../src/pages/events/featured.js" /* webpackChunkName: "component---src-pages-events-featured-js" */),
  "component---src-pages-events-parklife-js": () => import("./../../../src/pages/events/parklife.js" /* webpackChunkName: "component---src-pages-events-parklife-js" */),
  "component---src-pages-events-warehouse-project-js": () => import("./../../../src/pages/events/warehouse-project.js" /* webpackChunkName: "component---src-pages-events-warehouse-project-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

